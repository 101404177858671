import axios from 'axios';
import { toBase64NoStartingWithData } from 'utils';
import { changeUploadFileProgressStatus, updateUploadFileProgress } from './hirizeFinSlice';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const parseInvoce = createAsyncThunk('hirizeFin/parseInvoce', async (data, { dispatch, rejectWithValue }) => {
  const { files } = data;

  dispatch(updateUploadFileProgress({ files }));

  const request_list = files.map(async (file, index) => {
    const postData = {
      file_name: file.name,
      payload: await toBase64NoStartingWithData(file),
      index,
    };
    return axios.post('api/invoice-parser-ui/make-request', postData).then(res => {
      const { index } = JSON.parse(res.config.data);
      const { success } = res.data;
      dispatch(changeUploadFileProgressStatus({ index, status: success ? 'success' : 'error' }));
      return res;
    });
  });

  try {
    const data = await axios.all(request_list).then(
      axios.spread((...res) => {
        return res.map(item => item.data);
      }),
    );
    return data[0];
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getAllHirizeFinList = createAsyncThunk(
  'hirizeFin/getAllHirizeFinList',
  async (data, { dispatch, rejectWithValue }) => {
    const { keyword, current_page } = data;
    const postData = {
      tag_ids: null,
      current_page: current_page ? current_page : null,
      keyword: keyword,
    };
    try {
      const { data } = await axios.post(`api/invoice-parser-ui/request/all`, postData);

      return data.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getInvoiceDetail = createAsyncThunk(
  'hirizeFin/getInvoiceDetail',
  async (data, { dispatch, rejectWithValue }) => {
    const { id } = data;
    try {
      const { data } = await axios.get(`api/invoice-parser-ui/${id}`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const deleteInvoice = createAsyncThunk(
  'hirizeFin/deleteInvoice',
  async (data, { dispatch, rejectWithValue }) => {
    const { ids } = data;
    try {
      const { data } = await axios.post(`api/invoice-parser-ui/delete`, {
        ids: ids,
      });

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const changeInvoiceFileName = createAsyncThunk(
  'hirizeFin/changeInvoiceFileName',
  async (postData, { dispatch, rejectWithValue }) => {
    const { id, name, current_page } = postData;

    try {
      const { data } = await axios.post(`api/invoice-parser-ui/${id}/name`, { name });

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getAllHirizeFinList({ current_page }));
    }
  },
);
